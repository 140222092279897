<script>
import Layout from "../../layouts/main";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
  },
  data() {
    return {
      Student: null,
    };
  },
  created() {
    this.Student = JSON.parse(localStorage.getItem("user"));
  },
};
</script>

<template>
  <layout>
    <b-col>
      <b-row>
        <b-col>
          <div style="margin:auto; width:70px;">
            <i
              style="color:red;font-size:70px; "
              class="ri-user-3-line align-middle "
            ></i>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div style="margin:auto; width:auto; text-align: center;">
            <h5>{{Student.username}}</h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div style="margin:auto;  text-align: center;">
            <h5>{{Student.email}}</h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <hr class="solid" />
        </b-col>
      </b-row>
     
      <b-row>
        <b-col>
          <div style="margin:auto;  ">
            <h5>Né le : {{Student.birthday}}</h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <hr class="solid" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div style="margin:auto;  ">
            <h5>Adresse : {{Student.address}}</h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <hr class="solid" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div style="margin:auto;  ">
            <h5>Sexe : {{Student.gender}}</h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <hr class="solid" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div style="margin:auto;  ">
            <h5>Date d'inscription : {{Student.memberSince}}</h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <hr class="solid" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div style="margin:auto;  ">
            <h5>Occupation : {{Student.occupation}}</h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <hr class="solid" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div style="margin:auto;  ">
            <h5>Ecole : {{Student.schoolname}}</h5>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <hr class="solid" />
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div style="margin:auto;  ">
            <h5>Vérifié : {{ Student.isaproved?"✔️":"❌" }} </h5>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </layout>
</template>
<style>
hr.solid {
  border-top: 3px solid #bbb;
}
</style>
